var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{ref:"salaryTable",staticClass:"salaries-table",attrs:{"headers":_vm.headers,"items":_vm.salaries,"hide-default-footer":_vm.hideDefaultFooter,"footer-props":{ 'items-per-page-options': [10, 20, 50, -1] },"options":_vm.options,"expanded":_vm.expanded,"show-expand":"","loading":_vm.loading,"sort-by":"created_at","sort-desc":""},on:{"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.clickRow,"current-items":_vm.emitCurrentItems},scopedSlots:_vm._u([(_vm.displayCompanyName)?{key:"item.company.name",fn:function(ref){
var item = ref.item;
return [_c('h4',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [(item.company.published)?_c('router-link',{class:{ 'text-decoration-underline': hover },attrs:{"to":_vm.companyPath(item)}},[_vm._v(" "+_vm._s(item.company.name)+" ")]):_c('a',{staticClass:"black--text"},[_vm._v(_vm._s(item.company.name))])]}}],null,true)}),_c('br'),_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(item.city.name))])],1)]}}:null,{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.asDate(item.created_at))+" ")]}},{key:"item.years_at_company",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.years_at_company || "-")+" ")]}},{key:"header.annual_compensation",fn:function(ref){
var header = ref.header;
return [_c('span',[_vm._v(" "+_vm._s(header.text)),_c('br'),_c('v-icon',{staticClass:"mr-0",attrs:{"size":"12","left":""}},[_vm._v(" mdi-plus ")]),_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.$t("signing_bonus")))])],1)]}},{key:"item.annual_compensation",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.show_verified)?_c('v-icon',_vm._g(_vm._b({staticClass:"mb-1 mr-1",attrs:{"color":"green lighten-1","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-checkbox-marked-circle ")]):_vm._e()]}}],null,true)},[_c('submit-compensation-modal')],1),_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.displayCompensationItem(item.annual_compensation)))])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.signing_bonus_total)?_c('v-chip',_vm._g(_vm._b({attrs:{"color":"#E9F8EB","text-color":"success","label":"","small":""}},'v-chip',attrs,false),on),[_c('v-icon',{staticClass:"mr-0",attrs:{"size":"12","left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" "+_vm._s(_vm.displayCompensationItem(item.signing_bonus_total))+" ")],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("signing_bonus")))])])]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('salary-table-row-drop-down-content',{attrs:{"salaryItem":item,"headers":headers,"company-name":_vm.companyName || item.company.name}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }