<template>
  <td :colspan="headers.length">
    <v-container class="pt-5 pb-4">
      <v-row class="pr-sm-12">
        <!-- Additional info title and share button -->
        <v-col class="flex-grow-1 flex-sm-grow-0">
          <v-row dense>
            <v-col>
              <span class="text-h6">{{ $t("additional_information") }}</span>
            </v-col>
          </v-row>
          <v-row dense class="mt-3">
            <v-col>
              <v-btn
                color="secondary"
                rounded
                outlined
                class="bg-white"
                :to="
                  $i18nRoute({
                    name: 'salaryShow',
                    params: {
                      id: salaryItem.id
                    }
                  })
                "
                >{{ $t("share") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
        <!-- Information about the individual -->
        <v-col>
          <v-row dense
            ><v-col
              >{{ $t("gender") }} {{ $t(salaryItem.gender) }}</v-col
            ></v-row
          >
          <v-row dense>
            <v-col>
              {{ $t("education_level") }} {{ $t(salaryItem.education_level) }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>{{ $t("age") }} {{ $t(salaryItem.age) }}</v-col></v-row
          >
        </v-col>
        <!-- Salary break down -->
        <v-col sm="auto" cols="12" class="flex-grow-1 border-t-dashed-gray-1">
          <v-row dense>
            <v-col class="text-right">
              {{ $t("annual_base_salary") }}
            </v-col>
            <v-col class="text-right" cols="4" sm="2">
              {{ displayCompensationItem(salaryItem.annual_salary) }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="text-right">
              {{ $t("annual_bonus") }}
            </v-col>
            <v-col class="text-right" cols="4" sm="2">
              {{ displayCompensationItem(salaryItem.annual_bonus) }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="text-right">
              {{ $t("annual_stock") }}
            </v-col>
            <v-col class="text-right" cols="4" sm="2">
              {{ displayCompensationItem(salaryItem.annual_stock) }}
            </v-col>
          </v-row>
          <template v-if="salaryItem.signing_bonus_total">
            <!-- Signing bonus total -->
            <v-row dense>
              <v-col class="text-right">
                {{ $t("signing_bonus") }}
              </v-col>
              <v-col class="text-right" cols="4" sm="2">
                <!-- {{ salaryItem.annual_bonus_1 }} -->
                {{ displayCompensationItem(salaryItem.signing_bonus_total) }}
              </v-col>
            </v-row>
            <!-- Signing bonus breakdown -->
            <v-row
              dense
              v-for="key in showingSigningBonuses"
              :key="key"
              class="gray_darker--text"
            >
              <v-col class="text-right">
                {{ $t("nth_year", { number: key.match(/\d$/) }) }}
              </v-col>
              <v-col class="text-right" cols="4" sm="2">
                {{ displayCompensationItem(salaryItem[key]) }}
              </v-col>
            </v-row>
          </template>
        </v-col>
      </v-row>
      <!-- Reporting -->
      <v-row dense class="text-right">
        <v-col>
          <v-icon small class="mr-1">
            mdi-alert-octagon
          </v-icon>
          <a
            :href="reportItemLink(salaryItem)"
            target="_blank"
            class="gray_darker--text"
          >
            {{ $t("report_item") }}
          </a>
        </v-col>
      </v-row>
    </v-container>
  </td>
</template>

<i18n>
  {
    "en": {
    "additional_information": "Additional Information",
    "gender": "Gender:",
    "male": "Male",
    "female": "Female",
    "other": "Other",
    "unknown": "Unknown",
    "education_level": "Education Level:",
    "age": "Age:",
    "annual_compensation": "Total Compensation",
    "annual_base_salary": "Base Salary",
    "annual_bonus": "Bonus",
    "annual_stock": "Equity",
    "signing_bonus": "Signing Bonus",
    "nth_year": "Year {number}",
    "report_item": "Report something wrong with this entry",
    "report_issue": "Report issue with salary entry",
    "describe_problem": "Describe what is wrong with this entry:",
    "company_name": "Company",
    "grade": "Grade/Level",
    "role": "Role",
    "focus": "Focus",
    "share": "Share"
  },
  "ja": {
    "additional_information": "補足情報",
    "gender": "性別：",
    "male": "男性",
    "female": "女性",
    "other": "その他",
    "unknown": "不明",
    "education_level": "最終学歴:",
    "age": "年齢：",
    "annual_compensation": "年収",
    "annual_base_salary": "基本給(年)",
    "annual_bonus": "賞与(年)",
    "annual_stock": "株式報酬(年)",
    "signing_bonus": "サインオンボーナス",
    "nth_year": "{number}年目",
    "report_item": "問題を報告する",
    "report_issue": "年収情報の誤りを報告する",
    "describe_problem": "通報内容をご記入ください：",
    "company_name": "企業名",
    "grade": "グレード・レベル",
    "role": "職種",
    "focus": "フォーカス",
    "share": "年収をシェア"
  }
}
</i18n>
<script>
import { displayCompensationItemMixin } from "../../mixins/displayCompensationItemMixin";

export default {
  mixins: [displayCompensationItemMixin],
  name: "SalaryTableRowDropDownContent",
  props: {
    salaryItem: { type: Object, required: true },
    companyName: { type: String, required: true },
    headers: { type: Array, required: true }
  },
  data: () => ({}),
  computed: {
    showingSigningBonuses() {
      const regex = /^signing_bonus_\d$/;
      return Object.keys(this.salaryItem).filter(key => {
        return regex.test(key) && this.salaryItem[key];
      });
    }
  },
  created() {},
  methods: {
    reportItemLink(item) {
      const subject = encodeURIComponent(
        `${this.$t("report_issue")} (id: ${item.id})`
      );
      const body = encodeURIComponent(
        `Id: ${item.id}\n` +
          `${this.$t("company_name")}: ${this.companyName}\n` +
          `${this.$t("role")}: ${item.role_title}\n` +
          `${this.$t("focus")}: ${item.role_focus}\n` +
          `${this.$t("grade")}: ${item.grade}\n` +
          `${this.$t("annual_compensation")}: ${this.displayCompensationItem(
            item.annual_compensation
          )}\n\n` +
          `${this.$t("describe_problem")}`
      );

      return `mailto:hello@opensalary.jp?subject=${subject}&body=${body}`;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/settings/_variables"; // used for media queries

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  ::v-deep tbody {
    display: block;
  }
  .salaries-table tr.v-data-table__expanded__content td {
    background-color: var(--v-gray_lighter-base);
    width: 50%;
  }
}
@media #{map-get($display-breakpoints, 'xs-only')} {
  .border-t-dashed-gray-1 {
    border-top: 1px dashed var(--v-gray-base);
  }
}

.company-card {
  &:hover {
    background-color: var(--v-gray_lighter-base);
  }
}
.salaries-table table {
  // prevents table horizontal scroll
  table-layout: fixed;
  width: 98% !important;
}

.salaries-table tr.v-data-table__expanded__content td {
  background-color: var(--v-gray_lighter-base);
  cursor: default;
}

.bg-white {
  background-color: white;
}
</style>
