<template>
  <v-card>
    <v-container>
      <v-row justify="center" class="text-center">
        <v-col xs="12" sm="10" md="10">
          <p class="font-weight-bold">
            {{ $t("verified_dialog_title") }}
          </p>
          <v-card-text>
            <i18n path="verified_dialog">
              <router-link
                :to="{
                  path: locale.base + '/verified-salaries'
                }"
                >{{ $t("verified") }}</router-link
              >
            </i18n>
            <p class="font-weight-bold mt-6 mb-0">
              {{ $t("contribute_salaries") }}
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="secondary"
              :to="{ path: locale.base + '/submit-compensation-selection' }"
              dark
              rounded
            >
              {{ $t("submit_compensation") }}
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import store from "store";

export default {
  computed: {
    locale() {
      return store.getters.locale;
    }
  }
};
</script>

<i18n>
{
  "en": {
    "submit_compensation": "Submit Compensation",
    "verified_dialog_title": "This salary was verified with official documentation so you can be confident it is accurate",
    "verified_dialog": "Contributors have the option of sending in verification documents when they submit their information. Accepted documents include offer letters, change of compensation documents, payslips or tax withholding forms. {0}.",
    "verified": "Read more about verified salaries",
    "contribute_salaries": "Want to contribute, add yours!"
  },
  "ja": {
    "submit_compensation": "年収を提出",
    "verified_dialog_title": "この給与は、正式な書類によって証明・認証済みのため、正確なデータであることが証明されています",
    "verified_dialog": "データをご提供頂ける場合、データの提出時に正式な書類を提出することが可能です。受領可能な種類は、オファーレーター、給与書類、給与明細票、源泉徴収票です。詳細は、 {0}を参照ください。",
    "verified": "認証済みの給与",
    "contribute_salaries": "あなたの情報も提供してエンジニアのパワーアップに貢献しましょう!"
  }
}
</i18n>
