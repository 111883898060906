<template>
  <div v-if="company">
    <hr class="yellow-rule" />
    <v-container>
      <v-row>
        <v-breadcrumbs :items="breadCrumbs" large></v-breadcrumbs>
      </v-row>
      <!-- Switcher for roles -->
      <v-row class="role-filter" align="center" justify="space-between">
        <v-col
          align-self="center"
          class="d-flex align-start align-sm-center flex-column flex-sm-row"
        >
          <h1 class="mr-2 text-no-wrap text-subtitle-1 mb-2 mb-sm-0">
            <v-icon class="mr-1">mdi-magnify</v-icon>{{ $t("filter_role") }}
          </h1>
          <v-select
            @change="switchRole"
            hide-details
            v-model="currentRole"
            :items="roles"
            item-text="name"
            item-value="slug"
            background-color="white"
            full-width
            outlined
            dense
          />
        </v-col>
        <v-spacer v-show="$vuetify.breakpoint.smAndUp" />
        <v-col class="flex-grow-0" v-show="$vuetify.breakpoint.smAndUp">
          <v-btn
            color="secondary"
            :to="$i18nRoute({ name: 'submitCompensationSelection' })"
            dark
            rounded
          >
            <v-icon left dark large>mdi-plus</v-icon>
            {{ $t("add_compensation") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h1 class="text-h6 text-sm-h4 font-weight-bold">
            {{ company.name }}{{ $t("role_salaries", { role: $t(role_key) }) }}
          </h1>
          <underline />
        </v-col>
      </v-row>
      <v-row v-show="loading" justify="center">
        <v-progress-circular indeterminate color="secondary" />
      </v-row>
      <v-row class="mb-4" v-show="!loading">
        <v-col class="text-center" cols="12" xs="12" sm="12" md="2">
          <div class="text-subtitle-2">{{ $t("median_compensation") }}</div>
          <div class="font-weight-bold">
            {{
              displayCompensationItem(this.company.salary_entries.stats.median)
            }}
          </div>
        </v-col>
        <v-col class="text-center" cols="4" md="2">
          <div class="text-caption">{{ $t("median_base") }}</div>
          <div class="font-weight-bold text-body-2">
            {{ displayCompensationItem(this.estimatedBase) }}
          </div>
        </v-col>
        <v-col class="text-center" cols="4" sm="4" md="2">
          <div class="text-caption">{{ $t("median_bonus") }}</div>
          <div class="font-weight-bold text-body-2">
            {{ displayCompensationItem(this.estimatedBonus) }}
          </div>
        </v-col>
        <v-col class="text-center" cols="4" sm="4" md="2">
          <div class="text-caption">{{ $t("median_equity_grant") }}</div>
          <div class="font-weight-bold text-body-2">
            {{ displayCompensationItem(this.estimatedStock) }}
          </div>
        </v-col>
        <v-col
          class="text-center"
          :class="{ 'median-border': !$vuetify.breakpoint.xs }"
          cols="6"
          sm="6"
          md="2"
        >
          <div class="text-subtitle-2">{{ $t("average_yoe") }}</div>
          <div class="font-weight-bold">{{ displayAverageYoE }}</div>
        </v-col>
        <v-col class="text-center median-border" cols="6" sm="6" md="2">
          <div class="text-subtitle-2">{{ $t("number_of_entries") }}</div>
          <div class="font-weight-bold">{{ displayNumOfEntries }}</div>
        </v-col>
      </v-row>
      <v-row v-if="ladder && role_slug === 'software-engineer' && !loading">
        <v-col cols="12">
          <div class="h6 font-weight-bold mt-6 mb-2 primary--text">
            {{ $t("salary_by_level") }}
          </div>
          <v-data-table
            class="mb-4"
            :headers="levelHeaders"
            :items="ladder.levels.items"
            hide-default-footer
          >
            <template v-slot:item.name="{ item }">
              <div>
                <div class="text-h6 font-weight-bold">
                  {{ item.name }}
                </div>
                <div class="caption">{{ item.alternate_name }}</div>
              </div>
            </template>
            <template v-slot:item.salary_entries="{ item }">
              <horizontal-scatter-chart
                :height="$vuetify.breakpoint.mobile ? 45 : 15"
                :width="200"
                :label="item.name"
                :dataset="item.salary_entries.items"
                :min="chartOptions.min"
                :max="chartOptions.max"
                :locale="locale.code"
              ></horizontal-scatter-chart>
            </template>
            <template v-slot:item.median_compensation="{ item }">
              {{ displayCompensationItem(item.median_compensation) }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="h6 font-weight-bold mt-6 mb-1 primary--text" cols="12">
          {{ $t("salary_by_focus") }}
        </v-col>
      </v-row>
      <v-row v-show="loading" justify="center">
        <v-progress-circular indeterminate color="secondary" />
      </v-row>
      <v-row v-if="!loading">
        <v-col
          v-for="(compensations, key) in salariesByFocus"
          :key="key"
          cols="12"
          lg="3"
          md="4"
          sm="6"
        >
          <v-card class="focus-card">
            <v-card-text>
              <v-row>
                <v-col align="center">
                  <div class="subtitle-2 primary--text">{{ key }}</div>
                  <div class="h6 font-weight-bold primary--text">
                    {{ displayFocusByMedian(compensations) }}
                  </div>
                  <div class="subtitle-2 primary--text">
                    {{
                      $tc("entries", compensations.length, {
                        count: compensations.length
                      })
                    }}
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h2>{{ $t("raw_data") }}</h2>
          <Underline />
        </v-col>
      </v-row>
      <!-- Salary data table -->
      <v-row>
        <v-col col="12">
          <salary-table
            :loading="loading ? 'grey' : false"
            class="mb-4"
            :salaries="company.salary_entries.items"
            :display-company-name="false"
            :company-name="company.name"
            :options="{ itemsPerPage: 10 }"
          />
        </v-col>
      </v-row>
      <v-row class="mb-4" justify="center">
        <h2>{{ $t("call_to_add") }}</h2>
      </v-row>
      <!-- CTA for salary entry submission and ladder submission -->
      <v-row justify="center">
        <v-btn
          class="mr-2"
          color="secondary"
          :to="$i18nRoute({ name: 'submitCompensationSelection' })"
          large
          dark
          rounded
        >
          <v-icon left dark large>mdi-plus</v-icon>
          {{ $t("add_compensation") }}
        </v-btn>
        <v-btn color="secondary" :href="formUrl" outlined rounded large>
          <v-icon left dark large>mdi-plus</v-icon>
          {{ $t("submit_ladder") }}
        </v-btn>
      </v-row>
    </v-container>
  </div>
</template>

<i18n>
{
  "en": {
    "role_salaries": " {currentRole} Salaries",
    "median_compensation": "Median Compensation",
    "median_base": "Median Base",
    "median_bonus": "Median Bonus",
    "median_equity_grant": "Median Equity Grant",
    "average_yoe": "Average YoE",
    "number_of_entries": "# of Entries",
    "entries": "{count} entry | {count} entries",
    "salaries": "Home",
    "company_listing": "Companies",
    "call_to_add": "Help others like you, add your own compensation!",
    "add_compensation": "Add your compensation",
    "input_date": "Date",
    "location": "Location",
    "grade": "Grade/Level",
    "role": "Role",
    "focus": "Focus",
    "years_at_company": "Years at Company",
    "years_of_experience": "Years of Experience",
    "annual_compensation": "Total Compensation",
    "salary_by_level": "Salary by Level",
    "salary_by_focus": "Salary by Focus",
    "annual_base_salary": "Base Salary",
    "annual_bonus": "Bonus",
    "annual_stock": "Equity",
    "median": "Median",
    "range": "Range",
    "raw_data": "Raw Data",
    "submit_ladder": "Submit Ladder",
    "meta_description": "See {count_of_salaries} individual salary entries for software engineers at {company_name} with a median of {median}.",
    "software_engineer": "Software Engineer",
    "product_manager": "Product Manager",
    "data_scientist": "Data Scientist",
    "filter_role": "Filter by Role",
    "salary_by_role": "Salary by Role",
    "role_salary": "{currentRole} Salaries"
  },
  "ja": {
    "role_salaries": "の{currentRole}年収",
    "median_compensation": "年収(中央値)",
    "median_base": "基本給(中央値)",
    "median_bonus": "賞与(中央値)",
    "median_equity_grant": "株式報酬(中央値)",
    "average_yoe": "平均経験年数",
    "number_of_entries": "件数",
    "entries": "{count} 件",
    "salaries": "ホーム",
    "company_listing": "企業一覧",
    "add_compensation": "年収を追加",
    "call_to_add": "皆さんの情報で、企業の年収をよりオープンに可視化しましょう！",
    "company_name": "企業名",
    "input_date": "追加日",
    "location": "所在地",
    "grade": "グレード・レベル",
    "focus": "フォーカス",
    "years_at_company": "勤続年数",
    "years_of_experience": "経験年数",
    "annual_compensation": "年収",
    "salary_by_level": "レベル毎の給与",
    "salary_by_focus": "フォーカス毎の給与",
    "annual_base_salary": "基本給(年)",
    "annual_bonus": "賞与(年)",
    "annual_stock": "株式報酬(年)",
    "median": "中央値",
    "range": "年収範囲",
    "raw_data": "生データ",
    "submit_ladder": "等級制度を提出",
    "meta_description": "{company_name}で働くソフトウェアエンジニアの年収情報{count_of_salaries}件と年収の中央値{median}はこちらから。",
    "software_engineer": "ソフトウェアエンジニア",
    "product_manager": "プロダクトマネージャー",
    "data_scientist": "データサイエンティスト",
    "filter_role": "職種で絞り込み",
    "salary_by_role": "職種別年収",
    "role_salary": "{currentRole}年収"
  }
}
</i18n>

<script>
import _ from "lodash";
import store from "store";
import api from "services/api";
import Underline from "components/Underline.vue";
import HorizontalScatterChart from "components/HorizontalScatter.chart.vue";
import SalaryTable from "components/salary_table/SalaryTable.vue";
import statistics from "../utils/statistics";
import { displayCompensationItemMixin } from "../mixins/displayCompensationItemMixin";

export default {
  mixins: [displayCompensationItemMixin],
  props: {
    role_slug: { type: String, required: true },
    company_slug: { type: String, required: true }
  },
  components: {
    Underline,
    HorizontalScatterChart,
    SalaryTable
  },
  data: () => ({
    loading: true,
    company: "",
    ladder: "",
    estimatedBase: null,
    estimatedStock: null,
    estimatedBonus: null,
    pagination: {
      current_page: 1
    },
    chartOptions: {
      min: null,
      max: null
    },
    currentRole: ""
  }),
  metaInfo() {
    const description = this.$t("meta_description", {
      company_name: _.get(this.company, "name"),
      count_of_salaries: _.get(this.company, "salary_entries.stats.count"),
      median: this.displayCompensationItem(
        _.get(this.company, "salary_entries.stats.median", this.locale.code)
      )
    });

    return {
      title: `${this.company.name}${this.$t("role_salaries", {
        role: this.$t(this.role_key)
      })}`,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: description
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          content: `${this.company.name}${this.$t("role_salaries", {
            role: this.$t(this.role_key)
          })}`
        },
        {
          vmid: "twitter:description",
          name: "twitter:description",
          content: description
        },
        {
          vmid: "og-title",
          property: "og:title",
          content: `${this.company.name}${this.$t("role_salaries", {
            role: this.$t(this.role_key)
          })}`
        },
        {
          vmid: "og-description",
          property: "og:description",
          content: description
        }
      ]
    };
  },
  created() {
    this.getCompany();
    this.getLadders();
    this.currentRole = this.role_slug;
  },
  watch: {
    $route() {
      this.getCompany();
      this.getLadders();
    }
  },
  methods: {
    switchRole() {
      const route = this.$i18nRoute({
        name: "showCompany",
        params: {
          company_slug: this.company_slug,
          role_slug: this.currentRole
        }
      });
      this.$router.replace(route);
    },
    getCompany() {
      this.loading = true;

      api
        .getCompany({
          slug: this.company_slug,
          query: { job_role: this.role_slug }
        })
        .then(resp => {
          this.company = resp.data;
          this.roles = resp.data.roles;
          this.currentRole = this.roles.find(
            role => role.slug === this.role_slug
          );
          this.setEstimatedBreakdown();
          this.loading = false;
        });
    },
    getLadders() {
      api.getLadders(this.company_slug).then(resp => {
        const ladder = resp.data.items[0];
        this.ladder = ladder;

        let minSalary;
        let maxSalary;

        if (ladder) {
          ladder.levels.items.forEach(function(level) {
            level.salary_entries.items.forEach(function(salaryEntry) {
              const annualComp = salaryEntry.annual_compensation;

              minSalary = !minSalary ? annualComp : minSalary;
              maxSalary = !maxSalary ? annualComp : maxSalary;
              if (annualComp < minSalary) {
                minSalary = annualComp;
              } else if (annualComp > maxSalary) {
                maxSalary = annualComp;
              }
            });
          });
        }

        this.chartOptions.min = Math.floor(minSalary / 500000) * 500000; //round down to nearest 500,000 yen
        this.chartOptions.max = Math.ceil(maxSalary / 500000) * 500000; //round down to nearest 500,000 yen
      });
    },
    displayFocusByMedian(compensationsByFocus) {
      const median = statistics.quantile(compensationsByFocus, 50);
      return this.displayCompensationItem(median);
    },
    roundToTheNearest(value, integer) {
      return Math.round(value / integer) * integer;
    },
    setEstimatedBreakdown() {
      const completedSalaryEntries = this.company.salary_entries.items.filter(
        each =>
          each.annual_bonus !== null &&
          each.annual_stock !== null &&
          each.annual_salary !== null
      );
      const median = statistics.average(
        _.map(completedSalaryEntries, "annual_compensation"),
        50
      );
      const medianAnnualBase = statistics.average(
        _.map(completedSalaryEntries, "annual_salary"),
        50
      );
      const medianAnnualStock = statistics.average(
        _.map(completedSalaryEntries, "annual_stock"),
        50
      );
      const medianAnnualBonus = statistics.average(
        _.map(completedSalaryEntries, "annual_bonus"),
        50
      );

      const baseAsPercentageOfTotal = medianAnnualBase / median;
      const stockAsPercentageOfTotal = medianAnnualStock / median;
      const bonusAsPercentageOfTotal = medianAnnualBonus / median;

      const overallMedian = this.company.salary_entries.stats.median;
      this.estimatedBase = this.roundToTheNearest(
        baseAsPercentageOfTotal * overallMedian,
        100000
      );
      this.estimatedStock = this.roundToTheNearest(
        stockAsPercentageOfTotal * overallMedian,
        100000
      );
      this.estimatedBonus = this.roundToTheNearest(
        bonusAsPercentageOfTotal * overallMedian,
        100000
      );
    }
  },
  computed: {
    role_key() {
      return _.snakeCase(this.$route.params.role_slug);
    },
    locale() {
      return store.getters.locale;
    },
    formUrl() {
      if (this.locale.code === "en") {
        return "https://forms.gle/i4cb81XYiojTYtb47";
      }
      return "https://forms.gle/ZQPZTrSuGwSrc6bq8";
    },
    breadCrumbs() {
      return [
        {
          text: this.$t("salaries"),
          to: this.$i18nRoute({ name: "home" })
        },
        {
          text: this.$t("company_listing"),
          href: this.locale.base + "/companies"
        },
        {
          text: this.company.name,
          href: this.$router.resolve(
            this.$i18nRoute({
              name: "showCompany",
              params: {
                company_slug: this.company.slug
              }
            })
          ).href
        },
        {
          text: this.$t("salary_by_role")
        },
        {
          text: this.$t("role_salary", { role: this.$t(this.role_key) })
        }
      ];
    },
    levelHeaders() {
      return [
        {
          text: this.$t("grade"),
          value: "name",
          width: "150"
        },
        {
          text: this.$t("range"),
          value: "salary_entries",
          sortable: false
        },
        {
          text: this.$t("median"),
          value: "median_compensation",
          sortable: false,
          align: "right",
          width: 110
        },
        {
          text: this.$t("years_of_experience"),
          value: "median_years_of_experience",
          sortable: false
        }
      ];
    },
    displayNumOfEntries: function() {
      return this.company.salary_entries.stats.count;
    },
    displayAverageYoE: function() {
      const yoe = _.map(
        this.company.salary_entries.items,
        "years_of_experience"
      );
      return Math.floor(statistics.average(yoe) * 10) / 10;
    },
    salariesByFocus: function() {
      const focusList = {};
      const companyEntries = this.company.salary_entries.items;
      companyEntries.forEach(function(entry) {
        if (entry.role_focus in focusList) {
          focusList[entry.role_focus].push(entry.annual_compensation);
        } else {
          const focusCompensations = [];
          focusCompensations.push(entry.annual_compensation);
          focusList[entry.role_focus] = focusCompensations;
        }
      });
      return focusList;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/settings/_variables"; // used for media queries

.salaries-table table {
  // prevents table horizontal scroll
  table-layout: fixed;
  width: 98% !important;
}

.median-border {
  border-left: solid 1px black;
}

.role-filter {
  background-color: var(--v-gray_lighter-base);
}
</style>
