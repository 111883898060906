<template>
  <v-data-table
    class="salaries-table"
    :headers="headers"
    :items="salaries"
    :hide-default-footer="hideDefaultFooter"
    :footer-props="{ 'items-per-page-options': [10, 20, 50, -1] }"
    :options="options"
    :expanded.sync="expanded"
    show-expand
    :loading="loading"
    @click:row="clickRow"
    sort-by="created_at"
    sort-desc
    @current-items="emitCurrentItems"
    ref="salaryTable"
  >
    <template v-if="displayCompanyName" v-slot:item.company.name="{ item }">
      <h4>
        <v-hover v-slot="{ hover }">
          <router-link
            v-if="item.company.published"
            :to="companyPath(item)"
            :class="{ 'text-decoration-underline': hover }"
          >
            {{ item.company.name }}
          </router-link>
          <a class="black--text" v-else>{{ item.company.name }}</a>
        </v-hover>
        <br />
        <span class="text-caption">{{ item.city.name }}</span>
      </h4>
    </template>
    <template v-slot:item.created_at="{ item }">
      {{ asDate(item.created_at) }}
    </template>
    <template v-slot:item.years_at_company="{ item }">
      {{ item.years_at_company || "-" }}
    </template>
    <template v-slot:header.annual_compensation="{ header }">
      <span>
        {{ header.text }}<br />
        <v-icon size="12" left class="mr-0">
          mdi-plus
        </v-icon>
        <span class="text-caption">{{ $t("signing_bonus") }}</span>
      </span>
    </template>
    <template v-slot:item.annual_compensation="{ item }">
      <div class="text-no-wrap">
        <v-dialog width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="item.show_verified"
              class="mb-1 mr-1"
              color="green lighten-1"
              small
              v-bind="attrs"
              v-on="on"
            >
              mdi-checkbox-marked-circle
            </v-icon>
          </template>
          <submit-compensation-modal />
        </v-dialog>
        <span class="text-no-wrap">{{
          displayCompensationItem(item.annual_compensation)
        }}</span>
      </div>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <!-- color="green" text-color="white" should produce the design we want from Vuetify 3 -->
          <v-chip
            color="#E9F8EB"
            text-color="success"
            label
            v-if="item.signing_bonus_total"
            small
            v-on="on"
            v-bind="attrs"
          >
            <v-icon size="12" left class="mr-0">
              mdi-plus
            </v-icon>
            {{ displayCompensationItem(item.signing_bonus_total) }}
          </v-chip>
        </template>
        <span>{{ $t("signing_bonus") }}</span>
      </v-tooltip>
    </template>
    <template v-slot:expanded-item="{ item, headers }">
      <salary-table-row-drop-down-content
        :salaryItem="item"
        :headers="headers"
        :company-name="companyName || item.company.name"
      />
    </template>
  </v-data-table>
</template>

<script>
import SalaryTableRowDropDownContent from "components/salary_table/SalaryTableRowDropDownContent.vue";
import SubmitCompensationModal from "components/salary_table/SubmitCompensationModal.vue";
import moment from "moment";
import _ from "lodash";
import store from "../../store";
import { displayCompensationItemMixin } from "../../mixins/displayCompensationItemMixin";

export default {
  mixins: [displayCompensationItemMixin],
  components: { SalaryTableRowDropDownContent, SubmitCompensationModal },
  props: {
    hideDefaultFooter: Boolean,
    options: {
      type: Object,
      default: () => ({})
    },
    searchQuery: Object,
    salaries: Array,
    loading: [String, Boolean],
    // This is true for views other than company show view
    displayCompanyName: {
      type: Boolean,
      default: true
    },
    // This is for company show view since item.company.name is not available
    companyName: String
  },
  data: () => ({
    expanded: []
  }),
  computed: {
    headers() {
      // hide company name column on CompanyShowView
      const headers = [
        {
          text: this.$t("grade"),
          value: "grade"
        },
        {
          text: this.$t("focus"),
          value: "role_focus",
          filter: value => {
            if (!this.searchQuery || !this.searchQuery.focus) return true;
            return this.termSearch(value, this.searchQuery.focus);
          }
        },
        {
          text: this.$t("years_at_company"),
          value: "years_at_company"
        },
        {
          text: this.$t("years_of_experience"),
          value: "years_of_experience",
          filter: value => {
            if (!this.searchQuery) return true;
            if (!this.searchQuery.yoe_from && !this.searchQuery.yoe_to)
              return true;
            return this.yoeSearch(value, this.searchQuery);
          }
        },
        {
          text: this.$t("annual_compensation"),
          value: "annual_compensation",
          align: "end",
          cellClass: "py-1"
        },
        {
          text: "",
          value: "data-table-expand"
        }
      ];

      const companyNameHeader = {
        text: this.$t("company_name"),
        value: "company.name",
        align: "start",
        filter: (value, searchText, item) => {
          if (!this.searchQuery || !this.searchQuery.company_name) return true;

          return this.companySearch(
            value,
            this.searchQuery.company_name,
            item.company
          );
        }
      };

      // Hide this for screen that is xs < width < lg
      const jobRole = {
        text: this.$t("role"),
        value: "job_role",
        filter: value => {
          if (!this.searchQuery || !this.searchQuery.role) return true;
          return this.termSearch(value, this.searchQuery.role);
        }
      };

      if (this.$vuetify.breakpoint.lgAndUp || this.$vuetify.breakpoint.xsOnly) {
        headers.splice(1, 0, jobRole);
      }

      // Hide this for screen that is xs < width < md if there's company name column
      const inputDate = {
        text: this.$t("input_date"),
        value: "created_at"
      };

      if (
        this.$vuetify.breakpoint[
          this.displayCompanyName ? "mdAndUp" : "smAndUp"
        ] ||
        this.$vuetify.breakpoint.xsOnly
      ) {
        headers.unshift(inputDate);
      }

      if (this.displayCompanyName) return [companyNameHeader, ...headers];
      return headers;
    },
    locale() {
      return store.getters.locale;
    }
  },
  methods: {
    emitCurrentItems() {
      // get all the salary entries that is needed for stats and charts and pass it to
      // the parent view for rendering the stats and charts
      const filteredItems = this.$refs.salaryTable?.$children[0].filteredItems;
      this.$emit("current-items", filteredItems);
    },
    // create router link to company show page
    companyPath({ company, job_role }) {
      const role_slug =
        _.kebabCase(job_role) === "product-manager"
          ? "product-manager"
          : "software-engineer";
      return {
        name: "showCompany",
        params: {
          company_slug: company.slug,
          role_slug,
          locale: this.locale.param
        }
      };
    },
    // convert ISO date into readable date string based on locale
    asDate(time) {
      return moment(time).format(
        this.locale.code === "en" ? "MM/DD/YY" : "YY/MM/DD"
      );
    },
    // expand row when clicked on row, not just chevron
    clickRow(item, event) {
      if (event.isExpanded) {
        const index = this.expanded.indexOf(item);
        this.expanded.splice(index, 1);
      } else {
        this.expanded.push(item);
      }
    },
    companySearch(value, typedText, company) {
      // TODO: improve the search for all Japanese characters
      // https://github.com/drewpterry/project-alpha/issues/732

      const hasValue = val => (val != null ? val : "");

      const nameEn = hasValue(company.name_en)
        .toString()
        .toLowerCase();
      const nameJa = hasValue(company.name_ja).toString();
      let searchTerm = hasValue(typedText);

      // if search term are English letters
      if (/^[a-zA-Z]/.test(searchTerm)) {
        searchTerm = searchTerm.toLowerCase();
      } else {
        searchTerm = searchTerm.toString();
      }

      const matchNameEn = nameEn.indexOf(searchTerm) > -1;
      const matchNameJa = nameJa.indexOf(searchTerm) > -1;

      return matchNameEn || matchNameJa;
    },
    yoeSearch(value, search) {
      const yoeFrom = search.yoe_from ? parseInt(search.yoe_from) : 0;
      const yoeTo = search.yoe_to ? parseInt(search.yoe_to) : 99;

      return value >= yoeFrom && value <= yoeTo;
    },
    termSearch(value, searchTerm) {
      // if search term and value are English letters
      [searchTerm, value] = [searchTerm, value].map(term => {
        return /^[a-zA-Z]/.test(term) ? term.toLowerCase() : term.toString();
      });

      if (searchTerm === "mobile") {
        const matchMobile = value.indexOf("mobile") > -1;
        const matchAndroid = value.indexOf("android") > -1;
        const matchiOS = value.indexOf("ios") > -1;
        return matchMobile || matchAndroid || matchiOS;
      }

      return value.indexOf(searchTerm) > -1;
    }
  }
};
</script>

<style lang="scss" scoped>
.salaries-table table {
  // prevents table horizontal scroll
  table-layout: auto;
}

.salaries-table ::v-deep {
  tbody {
    tr {
      cursor: pointer;
    }
  }
}

::v-deep .v-data-table__mobile-row__cell {
  display: flex;
  align-items: center;
  gap: 0.2rem;
}
</style>

<i18n>
{
  "en": {
    "company_name": "Company",
    "input_date": "Date",
    "grade": "Grade/Level",
    "role": "Role",
    "focus": "Focus",
    "years_at_company": "Years at Company",
    "years_of_experience": "Years of Experience",
    "annual_compensation": "Total Compensation",
    "signing_bonus": "Signing Bonus"
  },
  "ja": {
    "company_name": "企業名",
    "input_date": "追加日",
    "grade": "グレード・レベル",
    "role": "職種",
    "focus": "フォーカス",
    "years_at_company": "勤続年数",
    "years_of_experience": "経験年数",
    "annual_compensation": "年収",
    "signing_bonus": "サインオンボーナス"
  }
}
</i18n>
