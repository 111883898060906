import _ from "lodash";

function quantile(arrayOfDigits, percentile) {
  const sortedArray = _.sortBy(arrayOfDigits);
  const index = (percentile / 100) * (sortedArray.length - 1);
  let result;
  if (Math.floor(index) === index) {
    result = sortedArray[index];
  } else {
    const i = Math.floor(index);
    const fraction = index - i;
    result = sortedArray[i] + (sortedArray[i + 1] - sortedArray[i]) * fraction;
  }
  return result;
}

function average(arrayOfDigits) {
  let sum = 0;
  arrayOfDigits.forEach(number => {
    sum += number;
  });
  return sum / arrayOfDigits.length;
}

export default {
  quantile,
  average
};
