import { render, staticRenderFns } from "./SalaryTable.vue?vue&type=template&id=83e4a738&scoped=true&"
import script from "./SalaryTable.vue?vue&type=script&lang=js&"
export * from "./SalaryTable.vue?vue&type=script&lang=js&"
import style0 from "./SalaryTable.vue?vue&type=style&index=0&id=83e4a738&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83e4a738",
  null
  
)

/* custom blocks */
import block0 from "./SalaryTable.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VChip,VDataTable,VDialog,VHover,VIcon,VTooltip})
